import Vue from 'vue'

export default Vue.mixin({
  methods: {
    capitalizeFirstLetter: (str) => str.charAt(0).toUpperCase() + str.slice(1),
    formatWeight(weight) {
      const info = this.formatWeight2(weight)
      return `${info.weight} ${info.unit}`
    },
    formatWeight2(weight) {
      let uom = 'g'
      let w = 0

      if (weight > 999.99) {
        w = weight / 1000
        uom = 'kg'
      } else {
        w = weight
      }

      if (w > 0) {
        w = Math.round(w * 1000) / 1000
      }

      return { weight: w, unit: uom }
    },
  },
})
