import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/product_details/:s?',
    alias: '/q/:s?',
    name: 'Product Details',
    component: () => import('../views/ProductDetails.vue'),
  },
  {
    path: '/product_details2/:s?',
    alias: '/q2/:s?',
    name: 'Product Details',
    component: () => import('../views/ProductDetails2.vue'),
  },
  {
    path: '/status/machine',
    // alias: '/q/:s?',
    name: 'Machine Status',
    component: () => import('../views/MachineStatus.vue'),
  },
  {
    path: '/config/machine',
    // alias: '/q/:s?',
    name: 'Machine Configuration',
    component: () => import('../views/MachineMaster.vue'),
  },
  {
    path: '/product/test-certificates',
    name: 'Test Certificates',
    component: () => import('../views/TestCertificates.vue'),
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
];

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
